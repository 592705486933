// ManagePermissions.js

import React, { useState, useEffect } from 'react';
import Select from 'react-select'; // Import react-select
import './CSS/Dashboard.css'; // Adjust the path if necessary

const ManagePermissions = ({ backendUrl }) => {
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [nameFilter, setNameFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');

  useEffect(() => {
    fetchUsers();
    fetchProducts();
    fetchPermissions();
  }, []);

  // Fetch users
  const fetchUsers = async () => {
    const authToken = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/user/getusers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': authToken,
        },
      });
      const data = await response.json();
      setUsers(data.users || []);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Fetch products
  const fetchProducts = async () => {
    const authToken = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/product/get`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': authToken,
        },
      });
      const data = await response.json();
      setProducts(data.products || []);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  // Fetch permissions
  const fetchPermissions = async () => {
    const authToken = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/permissions/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': authToken,
        },
      });
      const data = await response.json();
      setPermissions(data.permissions || []);
      initializeUserPermissions(data.permissions || []);
    } catch (error) {
      console.error('Error fetching permissions:', error);
    }
  };

  // Initialize user permissions
  const initializeUserPermissions = (permissions) => {
    const userPerms = {};
    permissions.forEach((perm) => {
      const userId = typeof perm.userId === 'object' ? perm.userId._id : perm.userId;
      const productId =
        typeof perm.productId === 'object' ? perm.productId._id : perm.productId;
      if (!userPerms[userId]) userPerms[userId] = {};
      userPerms[userId][productId] = true;
    });
    setUserPermissions(userPerms);
  };

  // Handle selecting a user from the list
  const handleSelectUser = (user) => {
    setSelectedUser(user);

    // Set the selected products based on the user's current permissions
    const currentUserPermissions = userPermissions[user._id] || {};
    const selectedProductOptions = products
      .filter((product) => currentUserPermissions[product._id])
      .map((product) => ({
        value: product._id,
        label: product.title,
      }));
    setSelectedProducts(selectedProductOptions);
  };

  // Handle changes in the multi-select dropdown
  const handleProductSelection = (selectedOptions) => {
    setSelectedProducts(selectedOptions || []);
  };

  // Apply changes for the selected user
  const applyChanges = async () => {
    const authToken = localStorage.getItem('auth-token');
    const userId = selectedUser._id;
    try {
      const currentPermissions = userPermissions[userId] || {};
      const selectedProductIds = selectedProducts.map((option) => option.value);

      const newPermissions = {};
      selectedProductIds.forEach((productId) => {
        newPermissions[productId] = true;
      });

      // Find permissions to grant
      const permissionsToGrant = selectedProductIds.filter(
        (productId) => !currentPermissions[productId]
      );

      // Find permissions to revoke
      const currentProductIds = Object.keys(currentPermissions);
      const permissionsToRevoke = currentProductIds.filter(
        (productId) => !newPermissions[productId]
      );

      // Grant permissions
      for (const productId of permissionsToGrant) {
        await fetch(`${backendUrl}/permissions/grant`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': authToken,
          },
          body: JSON.stringify({ userId, productId }),
        });
      }

      // Revoke permissions
      for (const productId of permissionsToRevoke) {
        await fetch(`${backendUrl}/permissions/revoke`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': authToken,
          },
          body: JSON.stringify({ userId, productId }),
        });
      }

      alert('Permissions updated successfully.');
      // Refresh permissions and user permissions
      await fetchPermissions();
      setSelectedUser(null);
      setSelectedProducts([]);
    } catch (error) {
      console.error('Error applying changes:', error);
      alert('An error occurred while updating permissions.');
    }
  };

  // Handle back button
  const handleBack = () => {
    setSelectedUser(null);
    setSelectedProducts([]);
  };

  // Sorting users
  const sortUsers = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Filtering and sorting users
  const filteredUsers = React.useMemo(() => {
    let filtered = [...users];
    if (nameFilter) {
      filtered = filtered.filter((user) =>
        user.name.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }
    if (roleFilter) {
      filtered = filtered.filter((user) => user.role === roleFilter);
    }
    return filtered;
  }, [users, nameFilter, roleFilter]);

  const sortedUsers = React.useMemo(() => {
    let sortableUsers = [...filteredUsers];
    if (sortConfig.key) {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableUsers;
  }, [filteredUsers, sortConfig]);

  // Rendering
  if (selectedUser) {
    // Prepare options for react-select
    const productOptions = products.map((product) => ({
      value: product._id,
      label: product.title,
    }));

    return (
      <div className="form-group">
        <h2>Manage Permissions for {selectedUser.name}</h2>
        <button onClick={handleBack}>Back to User List</button>
        <div style={{ marginTop: '20px' }}>
          <label>Select Products:</label>
          <Select
            isMulti
            options={productOptions}
            value={selectedProducts}
            onChange={handleProductSelection}
            placeholder="Select products..."
          />
        </div>
        <div className="form-group" style={{ marginTop: '20px' }}>
          <button className="dashboard-content button" onClick={applyChanges}>
            Save Changes
          </button>
        </div>
      </div>
    );
  }

  // Render the list of users
  return (
    <div className="form-group">
      <h2>Manage Permissions</h2>
      <div>
        <label>
          Filter by Name:
          <input
            type="text"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </label>
        <label>
          Filter by Role:
          <select
            value={roleFilter}
            onChange={(e) => setRoleFilter(e.target.value)}
          >
            <option value="">All</option>
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
        </label>
      </div>
      <table>
        <thead>
          <tr>
            <th onClick={() => sortUsers('name')}>Name</th>
            <th onClick={() => sortUsers('email')}>Email</th>
            <th onClick={() => sortUsers('role')}>Role</th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((user, index) => (
            <tr
              key={user._id}
              onClick={() => handleSelectUser(user)}
              style={{
                backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff',
                cursor: 'pointer',
              }}
            >
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManagePermissions;
