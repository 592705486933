/*global videojs*/

import {isQualityEnabled, setAutoQualityHandler, setQualityLevelHandler} from "../handlers/quality-levels-handlers";
import {toggleMenu, selectMenuItem} from "../handlers/menu-handlers";

const MenuItem = videojs.getComponent('MenuItem');
const Button = videojs.getComponent('Button');

class SwitchQualityButton extends Button {
    constructor(player, options) {
        super(player, options);

        localStorage.setItem('selectedQualityIndex', '0');

        this.controlText("Switch between quality levels");
        this.addClass('vjs-switch-quality-button', 'vjs-menu-button', 'vjs-icon-hd');

        this.menuOpened = false;
        this.on('click', this.toggleQualityMenu.bind(this));
        this.on('touchstart', this.toggleQualityMenu.bind(this));

        const menu = this.createMenu();
        this.player_.el().insertBefore(menu, this.player_.el().firstChild);

        this.setupQualityLevels(this.autoItem_, menu);
    }

    toggleQualityMenu(event) {
        event.preventDefault();
        toggleMenu('qualityMenu');
    }

    createMenu() {
        const menu = document.createElement('div');
        menu.className = 'vjs-quality-menu';

        const autoItem = new MenuItem(this.player_, {
            label: 'Auto'
        });

        autoItem.addClass('vjs-auto-quality');
        autoItem.on('click', () => setAutoQualityHandler(this.player_, autoItem, menu));
        autoItem.on('touchstart', (event) => {
            event.preventDefault();
            setAutoQualityHandler(this.player_, autoItem, menu);
        });

        this.autoItem_ = autoItem;
        menu.appendChild(autoItem.el());

        return menu;
    }

    async setupQualityLevels(autoItem, menu) {
        const qualityLevels = this.player_.qualityLevels();

        const qualityNames = {
            144: 'LD',
            240: 'LD+',
            360: 'SD',
            480: 'SD+',
            576: 'SD++',
            720: 'HD',
            1080: 'FHD',
            1440: 'QHD',
            2160: 'UHD',
            4320: '8K UHD'
        };

        let autoItemExists = true;

        const updateMenuVisibility = () => {
            const numQualityLevels = qualityLevels.length;

            if (numQualityLevels === 1) {
                if (autoItemExists) {
                    autoItem.el().remove();
                    autoItemExists = false;
                }

                const qualityLevel = qualityLevels[0];
                const qualityLabel = qualityLevel.height + 'p';
                const label = qualityNames[qualityLevel.height] ? `${qualityLabel} - ${qualityNames[qualityLevel.height]}` : qualityLabel;

                const qualityItems = Array.from(menu.querySelectorAll('.vjs-menu-item'));
                qualityItems.forEach(item => item.classList.remove('vjs-selected'));
                qualityItems[0].classList.add('vjs-selected');
            } else {
                if (!autoItemExists) {
                    menu.insertBefore(autoItem.el(), menu.firstChild);
                    autoItemExists = true;
                }
            }
        };

        qualityLevels.on('addqualitylevel', async (event) => {
            const qualityLevel = event.qualityLevel;
            const qualityLabel = qualityLevel.height + 'p';
            const label = qualityNames[qualityLevel.height] ? `${qualityLabel} - ${qualityNames[qualityLevel.height]}` : qualityLabel;

            if (await isQualityEnabled(qualityLevel)) {
                if (!Array.from(menu.children).some(item => item.innerText.includes(qualityLabel) && !/Auto/.test(item.innerText))) {
                    const qualityItem = new MenuItem(this.player_, { label });

                    qualityItem.on('click', () => setQualityLevelHandler(this.player_, qualityItem, qualityLevel, autoItem, menu));
                    qualityItem.on('touchstart', (event) => {
                        event.preventDefault();
                        setQualityLevelHandler(this.player_, qualityItem, qualityLevel, autoItem, menu);
                    });

                    menu.insertBefore(qualityItem.el(), autoItemExists ? autoItem.nextSibling : menu.firstChild);
                }
            }

            Array.from(menu.children).forEach(menuItem => {
                const menuItemText = menuItem.querySelector('.vjs-menu-item-text').textContent;
                const isAuto = /Auto/.test(menuItemText);
                const isQualityLevelPresent = Array.from(qualityLevels).some(ql => `${ql.height}p` === menuItemText.split(' ')[0]);

                if (!isAuto && !isQualityLevelPresent) {
                    menuItem.remove();
                }
            });

            updateMenuVisibility();
        });

        qualityLevels.on('change', () => {
            const selectedQualityLevel = qualityLevels[qualityLevels.selectedIndex];
            const selectedHeight = selectedQualityLevel ? selectedQualityLevel.height + 'p' : 'Auto';

            if (autoItemExists) {
                let autoLabel = 'Auto (' + (qualityNames[selectedQualityLevel.height] ? `${selectedHeight} - ${qualityNames[selectedQualityLevel.height]}` : selectedHeight) + ')';
                autoItem.el().querySelector('.vjs-menu-item-text').textContent = autoLabel;
            }

            const qualityItems = Array.from(menu.querySelectorAll('.vjs-menu-item'));
            selectMenuItem(qualityItems, 'selectedQualityIndex');
        });

        const qualityItems = Array.from(menu.querySelectorAll('.vjs-menu-item'));
        selectMenuItem(qualityItems, 'selectedQualityIndex');
    }
}

videojs.registerComponent('SwitchQualityButton', SwitchQualityButton);
export default SwitchQualityButton;