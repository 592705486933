/*global videojs*/

import {createRotateScreen, removeRotateScreen, setVideoContainerHeight} from '../render/screen-actions.js';

var intervalBlackScreen;

export function checkOrientation() {
    const isPortrait = window.innerHeight > window.innerWidth;
    if (isPortrait) {
        createRotateScreen();
    } else {
        removeRotateScreen();
    }
    setVideoContainerHeight()
}

export function checkForBlackScreen(isInitialization = false) {
    var player = videojs.getAllPlayers()[0];
    let videoElement = player.el().getElementsByTagName('video')[0];

    if (videoElement.duration > 0 && !player.paused() && player.readyState() > 2) {
        let canvas = document.createElement('canvas');
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        let ctx = canvas.getContext('2d');

        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        let data = imageData.data;
        let blackThreshold = 10; // Ajusta según la definición de "negro"
        let blackCount = 0;

        // Calcula las coordenadas para enfocarse en el 50% central del video
        let startX = canvas.width * 0.10;
        let endX = canvas.width * 0.20;
        let startY = 0;
        let endY = canvas.height;

        // Recorre solo la sección central del video
        for (let y = startY; y < endY; y++) {
            for (let x = startX; x < endX; x++) {
                let i = (y * canvas.width + x) * 4;
                let avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
                if (avg < blackThreshold) {
                    blackCount++;
                }
            }
        }

        // Ajusta la condición para considerar solo la sección central
        if (blackCount / ((endX - startX) * canvas.height) > 0.9) { // Ajusta el 0.9 según necesidad
            player.load();
            player.play();
        } else {
            if (isInitialization) {
                // player.muted(false);
                clearInterval(intervalBlackScreen);
                intervalBlackScreen = null; // Limpiar la referencia
            }
        }
    }
}