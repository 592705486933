/* global videojs */

import {selectMenuItem} from "./menu-handlers";
import {captureAndShowFrame, removeCapturedFrame} from "../render/screen-actions";

let seekedOccurred = false;
var player = null;

export function changeCam(requestedCamera, cameraItems) {
    player = videojs.getAllPlayers()[0];
    var currentVideoProjection = videojs.browser.IS_IOS ? player.vr().currentProjection_ : player.xr().options.projection;
    var playerCameraPosition;

    if (videojs.browser.IS_IOS) {
        playerCameraPosition = player.vr().camera.position;
    } else {
        playerCameraPosition = player.xr().camera.position;
    }

    const wasMuted = player.muted();
    player.muted(true);

    var customCurrentTime = player.currentTime();
    let currentAudioTrackLabel = null;

    for (let i = 0; i < player.audioTracks().length; i++) {
        if (player.audioTracks()[i].enabled) {
            currentAudioTrackLabel = player.audioTracks()[i].label ? player.audioTracks()[i].label : "Audio " + (i + 1);
            localStorage.setItem('selectedAudioLabel', currentAudioTrackLabel);
            localStorage.setItem('selectedQualityIndex', '0');
            break;
        }
    }

    if (videojs.browser.IS_IOS) {
        if (player.vr().currentProjection_ != window.videoTypes[requestedCamera]) {
            if (window.videoTypes[requestedCamera] == "2D") {
                player.vr().setProjection("NONE");
            } else {
                player.vr().setProjection(window.videoTypes[requestedCamera]);
            }
        }
    } else {
        if (player.xr().options.projection != window.videoTypes[requestedCamera]) {
            player.xr().options.projection = window.videoTypes[requestedCamera];
        }
    }

    captureAndShowFrame(player, currentVideoProjection);

    player.playlist.currentItem(requestedCamera);

    player.one('loadedmetadata', function() {
        const selectedSubtitleLabel = localStorage.getItem('selectedSubtitleLabel') || 'Disabled';
        const textTracks = player.textTracks();

        for (let i = 0; i < textTracks.length; i++) {
            const track = textTracks[i];
            if (track.kind === 'subtitles') {
                if (selectedSubtitleLabel === 'Disabled') {
                    track.mode = 'disabled';
                } else if (track.label === selectedSubtitleLabel) {
                    track.mode = 'showing';
                } else {
                    track.mode = 'disabled';
                }
            }
        }

        const subtitlesButton = player.controlBar.getChild('SubtitlesMenuButton');
        if (subtitlesButton) {
            subtitlesButton.updateMenuItems(textTracks);
        }
    });

    player.currentTime(customCurrentTime);
    player.userActive(false);

    if (videojs.browser.IS_IOS) {
        player.on("seeked", onSeeked);
        player.on("canplaythrough", onPlaying);
    }

    if (window.videoTypes[requestedCamera] == "NONE" || window.videoTypes[requestedCamera] == "2D") {
        player.el().classList.add('force-default-cursor');
    } else {
        player.el().classList.remove('force-default-cursor');
    }

    if (!videojs.browser.IS_IOS) {
        window.updateProjectionEnvironment(window.videoTypes[requestedCamera]);
    }

    player.one('playlistitem', function () {
        player.one('loadeddata', function () {
            if (videojs.browser.IS_IOS) {
                player.controlBar.fullscreenToggle.show();
                var fullscreenControl = document.querySelector('.vjs-fullscreen-control');
                fullscreenControl.classList.remove('vjs-hidden');
            }
        });
    });

    player.one('seeked', function () {
        selectMenuItem(cameraItems, 'selectedCameraIndex');

        if (window.videoTypes[requestedCamera] !== "2D" && window.videoTypes[requestedCamera] !== "NONE") {
            if (videojs.browser.IS_IOS) {
                player.vr().camera.position.y = playerCameraPosition.y;
                player.vr().camera.position.x = playerCameraPosition.x;
                player.vr().camera.position.z = playerCameraPosition.z;
            } else {
                player.xr().camera.position.y = playerCameraPosition.y;
                player.xr().camera.position.x = playerCameraPosition.x;
                player.xr().camera.position.z = playerCameraPosition.z;
            }
        }

        if ((currentVideoProjection === "2D" || currentVideoProjection === "NONE") && (window.videoTypes[requestedCamera] !== "2D" && window.videoTypes[requestedCamera] !== "NONE")) {
            if (videojs.browser.IS_IOS) {
                player.vr().camera.position.y = 0;
            } else {
                player.xr().camera.position.y = 1;
            }
        }

        player.one('progress', function () {
            const audioTracks = player.audioTracks();
            const selectedAudioLabel = localStorage.getItem('selectedAudioLabel');
            if (selectedAudioLabel) {
                for (let i = 0; i < audioTracks.length; i++) {
                    const audioTrack = audioTracks[i];
                    const audioName = audioTrack.label ? audioTrack.label : "Audio " + (i + 1);
                    audioTrack.enabled = (audioName === selectedAudioLabel);
                }
            }

            if (!wasMuted) {
                player.muted(false);
            }

            if (!videojs.browser.IS_IOS) {
                removeCapturedFrame();
            } else{
                var textTrackDisplayElement = document.querySelector('.vjs-text-track-display');
                if (textTrackDisplayElement && textTrackDisplayElement.classList.contains('vjs-hidden')) {
                    textTrackDisplayElement.classList.remove('vjs-hidden');
                }
            }
        });
    });

    player.play();
}

function onSeeked() {
    seekedOccurred = true;
}

function onPlaying() {
    if (seekedOccurred) {
        setTimeout(function() {
            removeCapturedFrame();
        }, 300);
        player.off("seeked", onSeeked);
        player.off("playing", onPlaying);
        seekedOccurred = false;
    }
}