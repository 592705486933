import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import LoginSignup from "./Pages/LoginSignup";
import UserDashboard from "./Pages/Dashboard";
import ProductTemplate from './Pages/ProductTemplate';

function App() {
  const [user, setUser] = useState(null);
  return (
    <Router>
      <AppContent user={user} />
    </Router>
  );
}

function AppContent({ user }) {
  const location = useLocation();
  // Regular expression to match '/product/:productId' routes
  const isProductPage = /^\/product\/[a-zA-Z0-9]+$/.test(location.pathname);
  const isAccountPage = location.pathname === "/account";

  return (
    <div className="App">
      {/* Show Navbar unless it's a product page */}
      {!isProductPage && <Navbar user={user} />}
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Pass isInline prop as true when rendering LoginSignup from /account */}
        <Route path="/account" element={<LoginSignup isInline={true} />} />
        <Route path="/Dashboard" element={<UserDashboard />} />
        <Route path="/product/:productId" element={<ProductTemplate />} />
      </Routes>
      {/* Show Footer unless it's a product page */}
      {!isProductPage && <Footer user={user} />}
    </div>
  );
}

export default App;
