import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CSS/ProductTemplate.css';
import { initIRPlayer } from '../intoreality-player/src/init';
import useAuth from '../hooks/useAuth';

window.isProductTemplateLoaded = false;

function ProductTemplate() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [event, setEvent] = useState(null);
  const [error, setError] = useState(null);
  const [accessMessage, setAccessMessage] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const { userData, authLoading, refreshUserData } = useAuth();

  useEffect(() => {
    window.isProductTemplateLoaded = true;

    const loadEvent = new Event('load');
    window.dispatchEvent(loadEvent);

    if (authLoading) {
      return; // Wait for authentication to complete
    }

    if (!userData) {
      // User is not authenticated; redirect to login
      navigate('/');
      return;
    }

    const checkPermissions = async () => {
      const authToken = localStorage.getItem('auth-token');
      if (!authToken) {
        navigate('/');
        return;
      }

      try {
        const response = await fetch(
          `${backendUrl}/permissions/check/${productId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'auth-token': authToken,
            },
          }
        );

        const data = await response.json();
        if (!data.hasPermission) {
          navigate('/');
          return;
        }

        // Fetch product and event data if the user has permissions
        await fetchProductAndEvent();
      } catch (error) {
        console.error('Error checking permissions:', error);
        setError('Failed to check permissions. Please try again.');
      }
    };

    const fetchProductAndEvent = async () => {
      try {
        const token = localStorage.getItem('auth-token');

        const productResponse = await fetch(
          `${backendUrl}/product/get/${productId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'auth-token': token,
            },
          }
        );
        const productData = await productResponse.json();

        if (productData.success) {
          const product = productData.product;
          if (!product.status) {
            setError('This product is inactive.');
            return;
          }

          const eventId = product.event._id;

          const eventResponse = await fetch(
            `${backendUrl}/event/get/${eventId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'auth-token': token,
              },
            }
          );
          const eventData = await eventResponse.json();

          if (eventData.success) {
            setEvent(eventData.event);
          } else {
            setError('Event not found');
          }
        } else {
          setError('Product not found');
        }
      } catch (err) {
        console.error(err);
        setError('An error occurred while fetching the product or event');
      }
    };

    checkPermissions();

    return () => {
      window.isProductTemplateLoaded = false;
    };
  }, [productId, backendUrl, userData, authLoading, navigate]);

  useEffect(() => {
    if (event) {
      const eventInformation = {
        allowed_referers: event.allowed_referers || [],
        audio_imgs: event.audio_imgs || [],
        audio_names: event.audio_names || [],
        cam_imgs: event.cam_imgs || [],
        cam_names: event.cam_names || [],
        captions_imgs: event.captions_imgs || [],
        canBeIframe: event.canBeIframe || false,
        product_id: productId,
        video_types: event.video_types || [],
        sources: event.sources || [],
      };

      initIRPlayer('my-video', eventInformation);
    }
  }, [event, productId]);

  if (accessMessage) {
    return <div>{accessMessage}</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <video-js
      id="my-video"
      className="video-js vjs-tech vjs-fluid vjs-default-skin vjs-16-9"
      controls
      crossOrigin="anonymous"
      playsInline
      preload="auto"
      width="640"
      height="264"
    ></video-js>
  );
}

export default ProductTemplate;
