import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = (redirectIfUnauthorized = true) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [userData, setUserData] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const navigate = useNavigate();

  const fetchUserData = async (shouldRedirect = redirectIfUnauthorized) => {
    const token = localStorage.getItem('auth-token');
    if (!token) {
      if (redirectIfUnauthorized && shouldRedirect) navigate('/account');
      setAuthLoading(false);
      setUserData(null);
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/user/getuserdata`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        setUserData({
          name: result.user.name,
          email: result.user.email,
          role: result.user.role,
          profileImage: result.user.profileImage || null,
        });
      } else {
        localStorage.removeItem('auth-token');
        if (redirectIfUnauthorized && shouldRedirect) navigate('/account');
        setUserData(null);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      localStorage.removeItem('auth-token');
      if (redirectIfUnauthorized && shouldRedirect) navigate('/account');
      setUserData(null);
    }
    setAuthLoading(false);
  };

  // Trigger initial fetch on component mount
  useEffect(() => {
    fetchUserData(); // Allow redirection during initial fetch

    // Listen for storage changes (e.g., login from another tab)
    const handleStorageChange = () => {
      fetchUserData(); // Allow redirection on storage changes
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const refreshUserData = () => {
    fetchUserData();
  };

  return { userData, authLoading, refreshUserData };
};

export default useAuth;
