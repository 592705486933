import { setVideoContainerHeight, handleOrientationChange } from '../render/screen-actions.js';
import { checkOrientation } from '../render/screen-checks.js';

const executeIfProductTemplateLoaded = (fn) => {
    return (...args) => {
        if (window.isProductTemplateLoaded) {
            fn(...args);
        }
    };
};

window.addEventListener('load', executeIfProductTemplateLoaded(checkOrientation));
window.addEventListener('resize', executeIfProductTemplateLoaded(checkOrientation));
window.addEventListener('orientationchange', executeIfProductTemplateLoaded(handleOrientationChange));
window.addEventListener('resize', executeIfProductTemplateLoaded(setVideoContainerHeight));