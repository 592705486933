// ProductInfoCard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProductInfoCard.css';
import useAuth from '../../hooks/useAuth';
import LoginSignup from '../../Pages/LoginSignup';

function ProductInfoCard({ product, handleClosePopup, showPopup }) {
  const navigate = useNavigate();

  // Disable automatic redirection by passing 'false' to useAuth
  const { userData, refreshUserData } = useAuth(false);
  const [showLogin, setShowLogin] = useState(false);
  const [accessMessage, setAccessMessage] = useState('');
  const [isCheckingAccess, setIsCheckingAccess] = useState(false);
  const [isPlayButtonClicked, setIsPlayButtonClicked] = useState(false);

  // Reset accessMessage when the popup is closed
  useEffect(() => {
    if (!showPopup) {
      setAccessMessage('');
      setIsPlayButtonClicked(false);
      setShowLogin(false);
    }
  }, [showPopup]);

  // Re-check permissions when userData changes or Play button is clicked
  useEffect(() => {
    if (userData && isPlayButtonClicked) {
      checkPermissions();
    }
  }, [userData, isPlayButtonClicked]);

  const handlePlayButtonClick = async () => {
    const authToken = localStorage.getItem('auth-token');

    // Check if the token exists; if not, prompt the user to log in
    if (!authToken) {
      setShowLogin(true);
      return;
    }

    // Reset state for a fresh check
    setAccessMessage('');
    setIsCheckingAccess(true);
    setIsPlayButtonClicked(true);

    // Refresh user data and then proceed with checking permissions
    await refreshUserData();
    // The checkPermissions function will be triggered by useEffect
  };

  const handleLoginSuccess = async () => {
    await refreshUserData();
    setShowLogin(false);
    setIsPlayButtonClicked(true); // Trigger a re-check of permissions after login
  };

  const checkPermissions = async () => {
    const authToken = localStorage.getItem('auth-token');
    if (!authToken) {
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/permissions/check/${product._id}`,
        { headers: { 'auth-token': authToken } }
      );
      const data = await response.json();
      if (data.hasPermission) {
        // Redirect to the product page if the user has access
        navigate(`/product/${product._id}`);
        if (handleClosePopup) {
          handleClosePopup();
        }
      } else {
        setAccessMessage('Access to this content is restricted.');
      }
    } catch (error) {
      console.error('Error checking permissions:', error);
      setAccessMessage('Failed to check permissions. Please try again.');
    }
    setIsCheckingAccess(false);
    setIsPlayButtonClicked(false); // Reset the Play button state after the check
  };

  if (!product) return null;

  return (
    <>
      {showPopup && (
        <div className="product-info-card-popup">
          <div className="product-info-card-content">
            {handleClosePopup && (
              <button className="product-info-card-close-button" onClick={handleClosePopup}>
                ✖
              </button>
            )}
            {showLogin ? (
              <LoginSignup
                onLoginSuccess={handleLoginSuccess}
                onClose={() => setShowLogin(false)}
                showCloseButton={true}
              />
            ) : (
              <>
                <img
                  src={product.featuredImage}
                  alt="Product"
                  className="product-info-card-image"
                />
                <div className="product-info-card-text">
                  <h3>{product.title}</h3>
                  <div className="product-info-card-action-container">
                    {isCheckingAccess ? (
                      <p className="product-info-card-loading-message">Loading...</p>
                    ) : accessMessage ? (
                      <p className="product-info-card-alert-message">{accessMessage}</p>
                    ) : (
                      <button
                        className="product-info-card-play-button"
                        onClick={handlePlayButtonClick}
                      >
                        ▶ Play Now
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ProductInfoCard;
