import React, { useEffect, useState } from 'react';

const ManageAuditTrails = ({ backendUrl, resetKey }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [emailFilter, setEmailFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [selectedLogs, setSelectedLogs] = useState(new Set());
  const [originalLogs, setOriginalLogs] = useState([]); // Store unfiltered logs

  const fetchLogs = async () => {
    const token = localStorage.getItem('auth-token');
    if (!token) return;

    try {
      const response = await fetch(`${backendUrl}/audit/auditlogs`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        setLogs(result.logs);
        setOriginalLogs(result.logs); // Keep a copy of unfiltered logs
        setSelectedLogs(new Set()); // Clear selected logs when re-fetching
      } else {
        console.error('Error fetching logs:', result.message);
      }
    } catch (error) {
      console.error('Error fetching logs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [backendUrl, resetKey]);

  const handleApplyFilters = () => {
    const filteredLogs = originalLogs.filter(log => {
      const matchesEmail = emailFilter ? log.email?.includes(emailFilter) : true;
      const matchesStartDate = startDateFilter ? new Date(log.eventTime) >= new Date(startDateFilter) : true;
      const matchesEndDate = endDateFilter ? new Date(log.eventTime) <= new Date(endDateFilter) : true;
      return matchesEmail && matchesStartDate && matchesEndDate;
    });
    setLogs(filteredLogs);
  };

  const handleClearFilters = () => {
    setEmailFilter("");
    setStartDateFilter("");
    setEndDateFilter("");
    setLogs(originalLogs); // Reset to unfiltered logs
  };

  const toggleLogSelection = (logId) => {
    setSelectedLogs((prevSelected) => {
      const updatedSelection = new Set(prevSelected);
      if (updatedSelection.has(logId)) {
        updatedSelection.delete(logId);
      } else {
        updatedSelection.add(logId);
      }
      return updatedSelection;
    });
  };

  const handleDeleteSelectedLogs = async () => {
    if (selectedLogs.size === 0) {
      alert("No logs selected for deletion.");
      return;
    }

    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/audit/auditlogs/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify({ logIds: Array.from(selectedLogs) }),
      });

      const result = await response.json();
      if (result.success) {
        alert("Selected logs deleted successfully.");
        fetchLogs(); // Refresh the logs after deletion
      } else {
        console.error('Error deleting logs:', result.message);
      }
    } catch (error) {
      console.error('Error deleting logs:', error);
    }
  };

  // Helper function to extract the browser name from the user agent
  const getBrowserName = (userAgent) => {
    if (userAgent.includes("Chrome")) return "Chrome";
    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) return "Safari";
    if (userAgent.includes("Firefox")) return "Firefox";
    if (userAgent.includes("Edge")) return "Edge";
    if (userAgent.includes("MSIE") || userAgent.includes("Trident")) return "Internet Explorer";
    return "Unknown";
  };

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <div>
      <h2>Audit Trails</h2>
      <div className="filters">
        <input
          type="text"
          placeholder="Filter by Email"
          value={emailFilter}
          onChange={(e) => setEmailFilter(e.target.value)}
        />
        <input
          type="date"
          placeholder="Start Date"
          value={startDateFilter}
          onChange={(e) => setStartDateFilter(e.target.value)}
        />
        <input
          type="date"
          placeholder="End Date"
          value={endDateFilter}
          onChange={(e) => setEndDateFilter(e.target.value)}
        />
        <button onClick={handleApplyFilters}>Apply Filters</button>
        <button onClick={handleClearFilters}>Clear Filters</button>
        <button style={{ backgroundColor: 'red' }} onClick={handleDeleteSelectedLogs}>Delete Selected Logs</button>
      </div>
      <table className="audit-trails-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={(e) =>
                  setSelectedLogs(
                    new Set(e.target.checked ? logs.map(log => log._id) : [])
                  )
                }
              />
            </th>
            <th>Email</th>
            <th>Event Type</th>
            <th>Event Time</th>
            <th>Details</th>
            <th>IP Address</th>
            <th>User Agent</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={log._id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedLogs.has(log._id)}
                  onChange={() => toggleLogSelection(log._id)}
                />
              </td>
              <td>{log.email || "N/A"}</td>
              <td>{log.eventType}</td>
              <td>{new Date(log.eventTime).toLocaleString()}</td>
              <td>{log.details?.productName || log.details?.page || "N/A"}</td>
              <td>{log.ipAddress}</td>
              <td>{getBrowserName(log.userAgent)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageAuditTrails;
