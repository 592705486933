/*global videojs*/

import { selectMenuItem } from './menu-handlers';

export function changeAudio(requestedAudioName, audioItems) {
    localStorage.setItem('selectedAudioLabel', requestedAudioName);
    const player = videojs.getAllPlayers()[0];
    const audioTracks = player.audioTracks();
    for (let i = 0; i < audioTracks.length; i++) {
        const audioTrack = audioTracks[i];
        const audioName = audioTrack.label ? audioTrack.label : "Audio " + (i + 1);
        audioTrack.enabled = (audioName === requestedAudioName);
    }
    selectMenuItem(audioItems, 'selectedAudioLabel');
}